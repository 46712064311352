@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* ----- CSS Reset start ------ */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  scroll-behavior: smooth;
}

/* Remove default margin in favour of better control in authored CSS */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin-block-end: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
  color: theme('textColor.gray.700');
  font-family: theme('fontFamily.sans');
}

label {
  font-weight: 500;
  font-size: 14px;
  font-family: theme('fontFamily.sans');
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
  overflow-x: hidden;
  font-size: 100%;
  font-family: theme('fontFamily.jakarta');
  text-rendering: optimizeSpeed;
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
  user-select: none;
  -moz-window-dragging: no-drag;
}

/* Inherit fonts for inputs and buttons */
input,
textarea,
select {
  font: 'font-jakarta';
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

div#root {
  min-height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
}

main {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
  html {
    scroll-behavior: initial;
  }
}
/* ----- CSS Reset end ------ */

/* Custom Styling */

h1 {
  font-weight: bold;
  font-size: theme('fontSize.3xl'); /**24px**/
}

h2 {
  font-weight: bold;
  font-size: theme('fontSize.2xl'); /**18px**/
}

h3 {
  font-weight: bold;
  font-size: theme('fontSize.xl'); /**16px**/
}

h4 {
  font-weight: bold;
  font-size: theme('fontSize.lg'); /**14px**/
}
h5 {
  font-weight: bold;
  font-size: theme('fontSize.md'); /**12px**/
}
h6 {
  font-weight: bold;
  font-size: theme('fontSize.sm'); /**10px**/
}

.required::after {
  content: ' (*)';
  color: red; /* Customize the color as needed */
  margin-right: 0.25rem; /* Adjust spacing between the asterisk and the text */
}

/* Hide arrows in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Hide arrows in Chrome, Safari, Edge, and Opera */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.active {
  background-color: #2993c6;
  border-radius: 50%;
}

.pagination-item {
  border-radius: 0.375rem;
  background-color: #e0f0f7;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  margin-right: 4px;
  margin-left: 4px;
  width: 36px;
  height: 36px;
  color: #2993c6;
}

.pagination-item-active {
  background-color: #2993c6;
  color: #e0f0f7;
}

.click-animation {
  @apply cursor-pointer transform transition-transform duration-200 active:scale-90 hover:scale-105;
}
